<template>
  <div class="container">
    <div class="row mt-5 mx-5" v-if="!isLoading">
      <div class="col-md-3">
        <img :src="movie.Poster" alt="TV Show Picture" class="simg">
      </div>
      <div class="col-md-9">
        <h4 class="mb-1">{{  movie.Title }} <small class="text-muted rating" v-if="movie.Ratings">{{  movie.Ratings[0].Value }} |</small><small class="text-muted genres"> {{  movie.Genre }}</small></h4> 
        <p v-html="movie.Plot"></p>
        <ul>
          <li><strong>Year:</strong> {{ movie.Year | yearCut}}</li>
          <li><strong>Language:</strong> {{ movie.Language }}</li>
          <li><strong>Writer(s):</strong> {{ movie.Writer }}</li>
        </ul>
        <div class="mb-3">
          <label for="btn" class="form-label">Source:</label><br/>
          <div class="btn-group w-50">
            <a href="#" class="btn btn-primary" :class="{'active': !selectedSource}" @click="selectedSource = false">Source 1</a>
            <a href="#" class="btn btn-primary" :class="{'active': selectedSource}" @click="selectedSource = true">Source 2</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mx-1">
      <div class="col-md-12 mx-4">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item show" :src="generatedUrlForEpisode" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="mt-5">
      
    </div>

  </div>
</template>

<script>
  export default {
    name: "Movie",
    data() {
      return {
        id: "",
        isLoading: true,
        selectedSource: false,
        key: "",
        movie: { Ratings: [{}] }      
      }
    },
    filters: {
      yearCut(year) {
        if(year == null) {
          return "???";
        }
        return year.substr(0, 4)
      }
    },
    computed: {
      generatedUrlForEpisode() {
        if (!this.selectedSource) {
          return "https://vidsrc.to/embed/movie/" + this.id;
        } else {
          return "https://vidsrc.xyz/embed/movie/" + this.id;
        }
      },
      selEp() {
        return parseInt(this.selectedEpisode) + 1;
      },
      episodesBySeason() {
        const seasons = {};
        this.episodes.forEach((episode) => {
          const season = episode.season;
          // Check if season doesn't exist in the output array, add it
          if (!Object.prototype.hasOwnProperty.call(seasons, season)) {
            seasons[season] = [];
          }
          seasons[season].push(episode);
        });
        const result = Object.entries(seasons).map(([season, episodes]) => ({
          season: parseInt(season),
          episodes: episodes.sort((a, b) => a.number - b.number)
        }));
        return result;
      }
    },
    methods: {
      moviebyid(query) {
        const url = new URL('https://www.omdbapi.com/');
        const params = {
          apikey: this.key,
          i: query
        }
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return fetch(url).then(res => res.json()).catch((err) => {
          alert("Error! " + err.toString());
        });
      },
      getMovie(id) {
        this.moviebyid(id).then((res) => {
          this.movie = res;
          this.isLoading = false;
        })
      }
    },
    mounted() {
      if(typeof this.$route.params.movie == 'undefined') {
        alert('Error');
        return;
      }
      if(typeof localStorage['omdbKey'] == 'undefined') {
        localStorage['omdbKey'] = '22c67895';
      }

      this.key = localStorage['omdbKey'];
      
      this.id = this.$route.params.movie;

      this.getMovie(this.$route.params.movie);
    }
  }
</script>

<style scoped>
  .show {
    width: 100% !important;
    height: 600px;
  }
  .scroll-list {
    max-height: 484px;
    overflow-y: auto;
  }
  .simg {
    height: 300px;
  }
  .rating, .genres {
    font-size: 50%;
  }
</style>
