<template>
  <div class="container mt-5">
    <input class="form-control mb-3" v-model="query" @input="searchInput" />
    <div class="row">
      <div class="col-md-2 mt-3" v-for="show in filteredShows" :key="show.id">
        <div class="card hh" v-if="show._embedded">
          <img :src="show._embedded.show.image.medium" class="card-img-top" :alt="show._embedded.show.name"
           data-bs-toggle="popover" 
           data-bs-trigger="hover focus"
           data-bs-placement="top"
           :data-bs-content="show._embedded.show.summary"
           data-bs-html="true"
           >
          <div class="card-body">
            <span class="badge bg-secondary mb-2">{{ show._embedded.show.premiered | yearCut }}</span>
            <h6 class="card-title"><router-link class="text-white tdn" :to="'show/' + show._embedded.show.id">{{  show._embedded.show.name }}</router-link></h6>
           
          </div>
        </div>
        <div class="card hh" v-else>
          <img :src="show.show.image.medium" class="card-img-top" :alt="show.show.name"
          data-bs-toggle="popover" 
           data-bs-trigger="hover focus"
           data-bs-placement="top"
           :data-bs-content="show.show.summary"
           data-bs-html="true"
          >
          <div class="card-body">
            <span class="badge bg-secondary mb-2">{{ show.show.premiered | yearCut }}</span>
            <h6 class="card-title"><router-link class="text-white tdn" :to="'show/' + show.show.id">{{  show.show.name }}</router-link></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Shows',
    data() {
      return {
        query: "",
        shows: [
        ]
      }
    },
    filters: {
      yearCut(year) {
        return year.substr(0, 4)
      }
    },
    computed: {
      filteredShows() {
        let data = JSON.parse(JSON.stringify(this.shows));
        if (data.length == 0) {
          return [];
        }
        if (this.query == "") {
          return data.filter(obj =>
            obj._embedded?.show?.externals?.imdb && obj._embedded.show.type !== 'Animation' && obj._embedded.show.type !== 'Documentary'
          );
        } else {
          return data.filter(obj =>
            obj.show?.externals?.imdb && obj.show.type !== 'Animation' && obj.show.type !== 'Documentary'
          );
        }
      }
    },
    methods: {
      kino(endpoint, params) {
        const url = new URL('https://api.tvmaze.com/' + endpoint);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return fetch(url).then(res => res.json()).catch((err) => {
          alert("Error! " + err.toString());
        });
      },
      searchInput() {
        if (this.query.length >= 3) {
          this.search(this.query);
        }
      },
      popoverUpdate() {
        setTimeout(() => {
        const popperInstances = document.querySelectorAll('.popover, .dropdown-menu');
        popperInstances.forEach(popper => {
            const popover = window.bootstrap.Popover.getInstance(popper);
            popover.hide();
        })
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new window.bootstrap.Popover(popoverTriggerEl))
        return popoverList;
        }, 300);
      },
      search(q) {
        this.kino("search/shows", {
          q: q
        }).then((res) => {
          this.shows = res;
          this.popoverUpdate();
        })
      },
      getLatest() {
        this.kino("schedule/web", {
          country: "US"
        }).then((res) => {
          console.log("Got latest:", res);
          this.shows = res;
          this.popoverUpdate();
          
        })
      }
    },
    mounted() {
      
      this.query = "Stargate";
      this.search("Stargate");
    },
    components: {
    }
  }
</script>

<style scoped>
.hh {
  height: 100%;
}
.popover {
  background: #000 !important;
  color: #fff;
}
.tdn {
  text-decoration: none;
}
.tdn:hover {
  text-decoration: underline;
}
</style>