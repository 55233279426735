import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Shows from '../views/Shows.vue'
import Movies from '../views/Movies.vue'
import Show from '../views/Show.vue'
import Movie from '../views/Movie.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/shows',
    name: 'Shows',
    component: Shows
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/show/:show',
    name: 'Show',
    component: Show
  },
  {
    path: '/movie/:movie',
    name: 'Movie',
    component: Movie
  },
  {
    path: '/movies',
    name: 'Movies',
    component: Movies
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
