<template>
  <div class="container mt-5 px-5" v-if="showContent">
    <h1 class="mt-5 text-center">BQ.TV📺</h1>
    <h5 class="mt-3 text-center">Your best option for watching English TV shows and movies online.</h5>
  </div>
</template>

<script>
  export default {
    name: "Home",
    data() {
      return {
        showContent: false
      }
    },
    mounted() {
      
      setTimeout(() => {
        this.showContent = true;
      }, 300);
    }
  }
</script>


