<template>
  <div id="app">


    <nav class="navbar navbar-expand-lg bg-dark fc" data-bs-theme="dark">
  <div class="container">
    <a class="navbar-brand" href="/">BQ<span v-if="hasLogin">.TV📺</span></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" v-if="hasLogin" id="navbarColor02">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <router-link class="nav-link" :class="{'active': $route.name == 'Shows'}" to="/shows">Shows</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link " :class="{'active': $route.name == 'Movies'}" to="/movies">Movies</router-link>
        </li>
      </ul>
      <ul class="navbar-nav me-right">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="logout" to="/">Exit</a>
        </li>
      </ul>
    </div>
  </div>
</nav>


    <router-view @loginSuccess="loginSuccess" />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      hasLogin: false,

    }
  },
  methods: {
    loginSuccess() {
      console.log("Event signals success login");
      this.hasLogin = true;
    },
    logout() {
      localStorage['__login'] = '';
      this.hasLogin = false;
      document.location.href = '/login';
    }
  },
  mounted() {
    if(!this.hasLogin && this.$route.name !== 'Login') {
      if(localStorage['__login'] !== 'a493f1f54207062b1f9903c409fba96a48037a41') {
        console.log("login needed");
        this.$router.push("/login");
      }
      else {
        this.hasLogin = true;
      }
    }
  }
}
</script>


<style scoped>
.fc {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #ddd;
}
.zaz::placeholder {
  color: #a0a0a0;
}

</style>
