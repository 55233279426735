<template>
  <div class="container">
    <div class="row mt-5 mx-5">
      <div class="col-md-3">
        <img :src="show.image.medium" alt="TV Show Picture" class="simg">
      </div>
      <div class="col-md-9">
        <h4 class="mb-1">{{ show.name }} <small class="text-muted rating">{{  show.rating.average }} / 10</small></h4> <span class="badge bg-secondary mb-3 mx-1" v-for="genre in show.genres" :key="genre">{{ genre }}</span>
        <p v-html="show.summary"></p>
        <ul>
          <li><strong>Year:</strong> {{ show.premiered | yearCut}} - {{ show.ended | yearCut }}</li>
          <li><strong>Language:</strong> {{ show.language }}</li>
          <li><strong>Status:</strong> {{ show.status }}</li>
        </ul>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-3">
        <div class="mb-3">
          <label for="btn" class="form-label">Source:</label>
          <div class="btn-group w-100">
            <a href="#" class="btn btn-primary" :class="{'active': !selectedSource}" @click="selectedSource = false">Source 1</a>
            <a href="#" class="btn btn-primary" :class="{'active': selectedSource}" @click="selectedSource = true">Source 2</a>
          </div>
        </div>
        <div class="mb-3">
          <label for="seasonSelect" class="form-label">Season:</label>
          <select class="form-select" id="seasonSelect" v-model="selectedSeason">
                        <option v-for="season in episodesBySeason" :value="season.season - 1" :key="season.season">Season {{ season.season }}</option>
                      </select>
        </div>
        <div class="mb-3">
          <label for="episodeSelect" class="form-label">Episode:</label>
          <ul class="list-group scroll-list">
            <a v-for="episode, index in episodesBySeason[selectedSeason].episodes" href="#" :key="episode.id" class="list-group-item" @click="selectedEpisode = index" :class="{'active': selectedEpisode == index}">{{ episode.name }}</a>
          </ul>
        </div>
      </div>
      <div class="col-md-9">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item show" :src="generatedUrlForEpisode" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="mt-5">
    </div>
  </div>
</template>

<script>
  export default {
    name: "Show",
    data() {
      return {
        show: {
          externals: {
            imdb: 0
          }
        },
        selectedSource: false,
        selectedSeason: 0,
        selectedEpisode: 0,
        episodes: []
      }
    },
    filters: {
      yearCut(year) {
        if (year == null) {
          return "ongoing";
        }
        return year.substr(0, 4)
      }
    },
    computed: {
      generatedUrlForEpisode() {
        if (!this.selectedSource) {
          return "https://vidsrc.to/embed/tv/" + this.show.externals.imdb + "/" + (parseInt(this.selectedSeason) + 1) + "/" + (parseInt(this.selectedEpisode) + 1);
        } else {
          return "https://vidsrc.xyz/embed/tv/" + this.show.externals.imdb + "/" + (parseInt(this.selectedSeason) + 1) + "-" + (parseInt(this.selectedEpisode) + 1);
        }
      },
      selEp() {
        return parseInt(this.selectedEpisode) + 1;
      },
      episodesBySeason() {
        const seasons = {};
        this.episodes.forEach((episode) => {
          const season = episode.season;
          // Check if season doesn't exist in the output array, add it
          if (!Object.prototype.hasOwnProperty.call(seasons, season)) {
            seasons[season] = [];
          }
          seasons[season].push(episode);
        });
        const result = Object.entries(seasons).map(([season, episodes]) => ({
          season: parseInt(season),
          episodes: episodes.sort((a, b) => a.number - b.number)
        }));
        return result;
      }
    },
    methods: {
      kino(endpoint, params) {
        const url = new URL('https://api.tvmaze.com/' + endpoint);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return fetch(url).then(res => res.json()).catch((err) => {
          alert("Error! " + err.toString());
        });
      },
      getShow(id) {
        this.kino("shows/" + id, {}).then((res) => {
          this.show = res;
        })
      },
      getEpisodes(id) {
        this.kino("shows/" + id + "/episodes", {}).then((res) => {
          this.episodes = res;
          console.log(this.episodesBySeason);
        })
      }
    },
    mounted() {
      if (typeof this.$route.params.show == 'undefined') {
        alert('Error');
        return;
      }
      this.getShow(this.$route.params.show);
      this.getEpisodes(this.$route.params.show);
      const popperInstances = document.querySelectorAll('.popover, .dropdown-menu');
      popperInstances.forEach(popper => {
        const popover = window.bootstrap.Popover.getInstance(popper);
        popover.hide();
      })
    }
  }
</script>

<style scoped>
  .show {
    width: 100% !important;
    height: 600px;
  }
  .scroll-list {
    max-height: 398px;
    overflow-y: auto;
  }
  .simg {
    height: 300px;
  }
  .rating {
    font-size: 50%;
  }
</style>
